var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Nội dung tin nhắn",
        visible: _vm.dialogVisible,
        width: "45%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "10vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "hr" }, [_c("hr")]),
      _c("div", { staticClass: "table-content row-data" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("span", { staticStyle: { "font-size": "20px" } }, [
              _vm._v("Nội dung :"),
            ]),
            _c("el-input", {
              attrs: { type: "textarea", rows: 12 },
              model: {
                value: this.viewContent[0].content,
                callback: function ($$v) {
                  _vm.$set(this.viewContent[0], "content", $$v)
                },
                expression: "this.viewContent[0].content",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }